<template>
  <layout-vertical>
    <template v-slot:navbar="{toggleVerticalMenuActive}">
      <div class="navbar-container d-flex content align-items-center">
        
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <b-link
                class="nav-link"
                @click="toggleVerticalMenuActive"
            >
              <feather-icon
                  icon="MenuIcon"
                  size="21"
              />
            </b-link>
          </li>
        </ul>

        <!-- Left Col -->
<!--        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">-->
<!--          <b-navbar-nav class="nav align-items-center mr-auto">-->
<!--            &lt;!&ndash;            <search-bar/>&ndash;&gt;-->
<!--          </b-navbar-nav>-->
<!--        </div>-->

        <ul class="navbar-nav nav align-items-center ml-auto">
          <!--          <dark-Toggler class="d-none d-lg-block" />-->
          <!--          <notification-dropdown/>-->
          <UserDropdown></UserDropdown>
        </ul>
      </div>
    </template>
    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import UserDropdown from "@/views/components/shared/UserDropdown";
import { BLink } from 'bootstrap-vue'


export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    UserDropdown,
    BLink
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
